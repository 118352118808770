import { Environments } from '.';

const qubitStagingId = '5776';
const qubitProductionId = '5775';

export const smartserveScriptIds: { [k: string]: string } = {
  [Environments.DEV]: qubitStagingId,
  [Environments.TST]: qubitStagingId,
  [Environments.ACC]: qubitStagingId,
  [Environments.PRD]: qubitProductionId,
};
export const ExperienceIds = {
  GSR2_DOT_5_DEV: '240512',
  GSR2_DOT_5_PRD: '240562', // https://g-star.atlassian.net/browse/TK-3412
  TK38_DEV: '241944',
  TK38_PRD: '241945', // https://g-star.atlassian.net/browse/FS-2663
  TK37_DEV: '241869',
  TK37_PRD: '241943', // https://g-star.atlassian.net/browse/FS-2667
};

export const StorageKeys = {
  TK38: 'qeId_tk38',
};
